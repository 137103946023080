// noinspection JSUnresolvedVariable
const settingConstants = require("./constants")
const cookie = require("cookie")

function Get_Slider_Data(locale, models, isMobile) {
    return new Promise((resolve, reject) => {
        models
            .sliders
            .find()
            .then((res) => {
                const sliders = JSON.parse(JSON.stringify(res));
                const filteredSliders = sliders.filter((slider) => {
                    const dateObject = new Date(slider.expired_at);
                    dateObject.setHours(0, 0, 0, 0)
                    const timestamp = dateObject.getTime();
                    if (slider.locale.includes(locale) && !slider.expired_at?.includes(new Date().toISOString().slice(0, 10))) {
                        if (slider.expired_at) {
                            return new Date().getTime() < timestamp
                        }
                        return true
                    }
                    return false
                });
                const slidersSortBy = filteredSliders.sort((a, b) => a.sort_order - b.sort_order)
                const sliderSettings = slidersSortBy.map(({mobile_path, path, ...elem}, index) => ({
                    ...elem,
                    path: isMobile ? mobile_path : path,
                    mobile_path,
                    // settings: index === 0 ? {priority: true, loading: "eager"} : {loading: "lazy"},
                    settings: index === 0 ? {loading: "eager"} : {loading: "lazy"},
                }));
                resolve({sliders: sliderSettings})
            })
            .catch((err) => reject(err));
    });
}

function Get_Chanel_Info(models, reqHeaders, domain, locale) {
    return new Promise((resolve, reject) => {
        models
            .channel_info
            .find()
            .lean()
            .then((res) => {
                const cookies = cookie.parse(reqHeaders.cookie || "");
                const selectedCurrency = cookies?.selectedCurrency;
                const [{base_currency_id, currencies_new, locales, default_locale_id = 1}] = res
                let updatedCurrArray = currencies_new.map(elem => {
                    if (!elem.exchange_rate) {
                        return {
                            ...elem,
                            exchange_rate: {rate: "1"}
                        }
                    }
                    return elem
                })
                const updatedLocales = locales.map(({locale_image, ...lang}) => {
                    if (locale_image) {
                        return {
                            ...lang,
                            locale_image: `/storage/${domain}/${locale_image}`
                        }
                    }
                    return {
                        ...lang,
                        locale_image: `/vendor/webkul/ui/assets/images/flag_${lang.code}.svg`
                    }
                })

                const defaultLangCode = (updatedLocales.find(({id: langId}) => langId === default_locale_id) || {})?.code || "en";
                const selectedLocale = locale !== "catchAll" ? locale : defaultLangCode
                if (!!selectedCurrency) {
                    const findCurrIndex = updatedCurrArray.findIndex(elem => elem.code === selectedCurrency)
                    if (findCurrIndex > -1) {
                        const deletedItem = updatedCurrArray.splice(findCurrIndex, 1);
                        updatedCurrArray.unshift(deletedItem[0]);
                    }
                    const updatedRes = [
                        {
                            ...res[0],
                            currencies_new: updatedCurrArray,
                            selectedLocale,
                            locales: updatedLocales,
                            selectedLocaleImg: (updatedLocales.find((lang) => lang?.code === selectedLocale) || {})?.locale_image || null,
                            filteredLocale: updatedLocales.filter((lang) => lang?.code !== selectedLocale)
                        }
                    ]
                    resolve({channel_info: JSON.parse(JSON.stringify(updatedRes))})
                } else {
                    const findCurrIndex = updatedCurrArray.findIndex(elem => elem.id === base_currency_id)
                    if (findCurrIndex > -1) {
                        const deletedItem = updatedCurrArray.splice(findCurrIndex, 1);
                        updatedCurrArray.unshift(deletedItem[0]);
                    }
                    const updatedRes = [
                        {
                            ...res[0],
                            currencies_new: updatedCurrArray,
                            selectedLocale,
                            locales: updatedLocales,
                            selectedLocaleImg: (updatedLocales.find((lang) => lang?.code === selectedLocale) || {})?.locale_image || null,
                            filteredLocale: updatedLocales.filter((lang) => lang?.code !== selectedLocale)
                        }
                    ]
                    resolve({channel_info: JSON.parse(JSON.stringify(updatedRes))})
                }
            })
            .catch((err) => reject(err));
    });
}

const Get_Socials = async (models) => {
    try {
        return await models.social.find({}) || []
    } catch (err) {
        console.log(err, "____Get_Socials____")
    }
}

function Get_Settings(locale, models) {
    const storeName = process.env.databaseName;
    const p1 = new Promise((resolve, reject) => {
        models
            .channel_info
            .find()
            .then((res) => resolve({channel_info: JSON.parse(JSON.stringify(res))}))
            .catch((err) => reject(err));
    });

    const p2 = new Promise((resolve, reject) => {
        models
            .social
            .find()
            .then((res) => {
                resolve({social: JSON.parse(JSON.stringify(res))})
            })
            .catch((err) => reject(err));
    });

    return new Promise((resolve, reject) => {
        Promise
            .all([p1, p2])
            .then(async (responseArray) => {
                let [customSettingsCollection] = await Promise.all([responseArray.reduce((acc, next) => {
                    const [key] = Object.keys(next);
                    return {
                        ...acc,
                        [key]: next[key],
                    };
                }, {})]);
                customSettingsCollection.folder_name = storeName;
                resolve(customSettingsCollection);
            })
            .catch((err) => reject(err));
    });
}

// function Get_Core_Config(locale, models) {
//     return new Promise((resolve, reject) => {
//         models
//             .core_config
//             .aggregate([
//                 {
//                     $match: {
//                         code: {
//                             $in: [
//                                 settingConstants.Phone,
//                                 settingConstants.Email,
//                                 settingConstants.Footer_Address,
//                                 settingConstants.Custom_Css,
//                                 settingConstants.Custom_Javascript,
//                                 settingConstants.Gdpr_Content,
//                                 settingConstants.Gdpr_Title,
//                                 settingConstants.Gdpr_Active,
//                                 settingConstants.Out_Of_Stock,
//                                 settingConstants.Guest_Checkout,
//                                 settingConstants.Backorders,
//                                 settingConstants.Blog_Active,
//                                 settingConstants.Contact_Us_Active,
//                                 settingConstants.Subscribe_Active,
//                                 settingConstants.Tracking_Active,
//                                 settingConstants.Linkedin_Active,
//                                 settingConstants.Facebook_Active,
//                                 settingConstants.Twitter_Active,
//                                 settingConstants.Github_Active,
//                                 settingConstants.Google_Active,
//                                 settingConstants.Cache_Version,
//                                 settingConstants.Footer_Toggle,
//                                 settingConstants.Footer_Content,
//                                 settingConstants.Recaptcha_active,
//                                 settingConstants.Recaptcha_key,
//                             ],
//                         },
//                     }
//                 },
//                 {
//                     $group: {
//                         _id: "$code",
//                         matchingDocuments: {
//                             $push: {
//                                 id: "$id",
//                                 value: "$value",
//                                 channel_code: "$channel_code",
//                                 locale_code: "$locale_code",
//                                 created_at: "$created_at",
//                                 updated_at: "$updated_at"
//                             }
//                         }
//                     }
//                 }
//             ])
//             .then((res) => {
//                 const core_conf = res
//                     .map(el => {
//                         let {_id, matchingDocuments} = el
//                         let key = _id.replace(/\./g, "_");
//                         let replaceSlash = key.replace(/\-/g, "_");
//                         return {[replaceSlash]: matchingDocuments};
//                     })
//                     .map(el => {
//                         for (let key in el) {
//                             if (el[key][0].locale_code && el[key][0].locale_code !== "tr") {
//                                 return {[key]: el[key].filter(elem => elem.locale_code === locale)}
//                             } else return {[key]: el[key][0]}
//                         }
//                     })
//                     .reduce((acc, next) => {
//                         return {
//                             ...acc,
//                             ...next,
//                         };
//                     }, {})
//                 resolve(core_conf);
//             })
//             .catch((err) => reject(err));
//     });
// }

async function Get_Core_Config(locale, models) {
    try {
        const result = await models.core_config.aggregate([
            {
                $match: {
                    code: {
                        $in: [
                            settingConstants.Phone,
                            settingConstants.Email,
                            settingConstants.Footer_Address,
                            settingConstants.Custom_Css,
                            settingConstants.Custom_Javascript,
                            settingConstants.Custom_Analytics_GTAG,
                            settingConstants.Gdpr_Content,
                            settingConstants.Gdpr_Title,
                            settingConstants.Gdpr_Active,
                            settingConstants.Out_Of_Stock,
                            settingConstants.Guest_Checkout,
                            settingConstants.Backorders,
                            settingConstants.Blog_Active,
                            settingConstants.Contact_Us_Active,
                            settingConstants.Subscribe_Active,
                            settingConstants.Tracking_Active,
                            settingConstants.Linkedin_Active,
                            settingConstants.Facebook_Active,
                            settingConstants.Twitter_Active,
                            settingConstants.Github_Active,
                            settingConstants.Google_Active,
                            settingConstants.Cache_Version,
                            settingConstants.Footer_Content,
                            settingConstants.Footer_Powered,
                            settingConstants.Recaptcha_active,
                            settingConstants.Recaptcha_key,
                            settingConstants.Blog_meta_title,
                            settingConstants.Blog_meta_description,
                            settingConstants.Blog_meta_keywords,
                            settingConstants.Suspend_Status,
                            settingConstants.Theme_account_active,
                            settingConstants.Theme_wishlist_active,
                            settingConstants.Theme_cart_active,
                            settingConstants.Theme_search_active,
                            settingConstants.Theme_categories_active,
                        ],
                    },
                },
            },
            {
                $group: {
                    _id: "$code",
                    matchingDocuments: {
                        $push: {
                            id: "$id",
                            value: "$value",
                            channel_code: "$channel_code",
                            locale_code: "$locale_code",
                            created_at: "$created_at",
                            updated_at: "$updated_at"
                        }
                    }
                }
            }
        ]);
        return result.map(({_id, matchingDocuments}) => {
            const key = _id.replace(/\./g, "_").replace(/\-/g, "_");

            if (matchingDocuments[0].locale_code && matchingDocuments[0].locale_code !== "tr") {
                return {[key]: matchingDocuments.filter(elem => elem.locale_code === locale)};
            } else {
                return {[key]: matchingDocuments[0]};
            }
        }).reduce((acc, next) => ({...acc, ...next}), {});
    } catch (error) {
        throw error;
    }
}

function Get_ShopId(query, models) {
    return new Promise((resolve, reject) => {
        let newQuery;
        if (typeof query === "object") {
            newQuery = query.locale
        } else {
            newQuery = query
        }
        models
            .core_config
            .findOne({code: newQuery})
            .then((res) => {
                resolve(JSON.parse(JSON.stringify(res)))
            })
            .catch((err) => reject(err));
    })

}

function Get_Translations_New(locale, intlToFind, models) {
    return new Promise((resolve, reject) => {
        models.translations.findOne({lang: locale})
            .then((res) => {
                let parsedData = {}
                if (res?.data) {
                    parsedData = JSON.parse(JSON.stringify(res.data))
                }
                const translationsConcat = {}
                intlToFind?.forEach(elem => {
                    const [intlKey] = Object.keys(elem)
                    const [intlValue] = Object.values(elem)
                    translationsConcat[intlValue] = parsedData[intlKey] ?? intlKey
                })
                resolve(translationsConcat)
            })
            .catch((err) => reject(err))
    })
}

function Get_Translations(query, models) {
    return new Promise((resolve, reject) => {
        let newQuery;

        if (typeof query === "object") {
            newQuery = query.locale
        } else {
            newQuery = query
        }
        models
            .translations
            .findOne({lang: newQuery})
            .then((res) => {
                resolve({[newQuery]: {translations: res?.data ? JSON.parse(JSON.stringify(res.data)) : {}}});
            })
            .catch((err) => reject(err));
    });
}

const Get_Exact_Core_Config = async (models, code) => {
    try {
        const result = await models.core_config.aggregate([{$match: {code: {$in: [code]}}}])
        return result[0]?.value;
    } catch (error) {
        throw error;
    }
};


module.exports = {
    Get_Settings,
    Get_Core_Config,
    Get_Translations,
    Get_ShopId,
    Get_Slider_Data,
    Get_Chanel_Info,
    Get_Exact_Core_Config,
    Get_Translations_New,
    Get_Socials
};
